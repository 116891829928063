import { Deal } from "@ifgengineering/client-invest-sdk";
import { signInAsync, signOutAsync } from "../auth/auth.actions";
import { fetchDealsAPI } from "./deal.actions";

export interface DealState {
  neverLoadedDeals: boolean;
  loading: boolean;
  data: Deal[];
}

export const INITIAL_STATE: DealState = {
  neverLoadedDeals: true,
  loading: false,
  data: [],
};

const dealReducer = (state = INITIAL_STATE, action: any): DealState => {
  switch (action.type) {
    case signInAsync.fulfilled.type:
    case signOutAsync.fulfilled.type:
      return {
        ...INITIAL_STATE,
      };
    case fetchDealsAPI.pending.type:
      return {
        ...state,
        loading: true,
      };
    case fetchDealsAPI.fulfilled.type:
      return {
        ...state,
        loading: false,
        neverLoadedDeals: false,
        data: [...action.payload],
      };
    default:
      return state;
  }
};

export default dealReducer;
