export enum MENU_IDS {
  BANK_DETAILS = "bank-details",
  LIVE_DEALS = "live",
  PORTFOLIO = "portfolio",
  PENDING_PAYMENTS = "pending-payments",
  IDENTITY = "identity",
  ZAKAT = "zakat",
}

export enum URLS {
  BANK_DETAILS = "/app/bank-details",
  LIVE_DEALS = "/app/deals",
  PORTFOLIO = "/app/portfolio",
  PENDING_PAYMENTS = "/app/pending-payments",
  IDENTITY = "/app/identity",
  ZAKAT = "/app/zakat",
}
