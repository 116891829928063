import * as Sentry from "@sentry/browser";
import { blockedException } from "./logDecisionObject";
import { SENTRY_REPLAY } from "gatsby-env-variables";

export const initSentry = (dsn: string): void => {
  const isDev = process.env.NODE_ENV === "development";
  const shouldEnableReplay = SENTRY_REPLAY === "true" && !isDev;

  const replayConfiguration = shouldEnableReplay
    ? {
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
      }
    : {};

  const integrations: any = [Sentry.browserTracingIntegration({})];

  if (shouldEnableReplay) {
    integrations.push(
      Sentry.replayIntegration({
        networkDetailAllowUrls: [/^https:\/\/api\.cur8\.capital/],
      })
    );
  }

  Sentry.init({
    dsn,
    ...replayConfiguration,
    integrations,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      /Illegal invocation/gi,
      // Sentry/replay throws it when on Windows for some reason
      /Cannot read properties of undefined (reading 'contains')/gi,
    ],

    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],

    beforeSend(event, hint) {
      const error = hint.originalException as any;

      // It filters the "Non-Error promise rejection captured with value: Timeout" error report
      if (error === "Timeout") return null;

      if (typeof error === "string" && blockedException(error)) {
        return null;
      }

      // When returning null, we tell Sentry to don't log this error
      if (typeof error?.stack === "string" && blockedException(error?.stack)) {
        return null;
      }

      // When returning the event we're telling it to log it.
      return event;
    },
  });
};
