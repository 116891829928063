import { navigate } from "gatsby";
import { MENU_IDS, URLS } from "./consts";
import {
  ButtonProps,
  INavigationItem,
} from "@ifgengineering/component-library";

export const goToHowItworksPage = (): Window | null =>
  window.open("https://cur8.capital/how-it-works", "_blank");

export const getHowItWorksItems = (
  showTutorial: () => void
): Array<ButtonProps> => [
  {
    icon: "PlayOne",
    text: "Tutorial",
    onClick: showTutorial,
  },
  {
    icon: "Info",
    text: "About Us",
    onClick: goToHowItworksPage,
  },
];

export const getFloatMenuItems = ({
  logoutCallback,
  onClickMyAccount,
  onClickMembership,
}: {
  logoutCallback: VoidFunction;
  onClickMyAccount: VoidFunction;
  onClickMembership: VoidFunction;
}): Array<Array<ButtonProps>> => {
  const menuFirstPart: Array<ButtonProps> = [
    {
      icon: "Setting",
      text: "Settings",
      onClick: onClickMyAccount,
    },
    {
      icon: "Diamonds",
      text: "Membership",
      onClick: onClickMembership,
    },
    {
      icon: "Mail",
      text: "Contact Us",
      onClick: () => window.open("https://cur8.capital/contact-us", "_blank"),
    },
  ];

  const floatingMenuItems: Array<Array<ButtonProps>> = [
    menuFirstPart,
    [
      {
        icon: "Logout",
        text: "Logout",
        iconColor: "ERROR800",
        textColor: "ERROR800",
        onClick: logoutCallback,
      },
    ],
  ];

  return floatingMenuItems;
};

export const getMenuItems = ({
  hideBankDetails,
  isZakatMenuEnabled,
}: {
  hideBankDetails: boolean;
  isZakatMenuEnabled: boolean;
}): Array<INavigationItem> => {
  const navItems: Array<INavigationItem> = [
    {
      id: MENU_IDS.LIVE_DEALS,
      title: "Live Deals",
    },

    {
      id: MENU_IDS.PORTFOLIO,
      title: "My Portfolio",
    },
  ];

  navItems.splice(1, 0, {
    id: MENU_IDS.PENDING_PAYMENTS,
    title: "Payments",
  });

  if (isZakatMenuEnabled) {
    navItems.push({
      id: MENU_IDS.ZAKAT,
      title: "Zakat",
    });
  }

  if (!hideBankDetails) {
    navItems.splice(0, 0, {
      id: MENU_IDS.BANK_DETAILS,
      title: "Startup Bank Details",
    });
  }

  return navItems;
};

export const mainMenuNavigation = (itemId: string): void => {
  const locationPerId: Record<string, string> = {
    [MENU_IDS.BANK_DETAILS]: URLS.BANK_DETAILS,
    [MENU_IDS.LIVE_DEALS]: URLS.LIVE_DEALS,
    [MENU_IDS.PORTFOLIO]: URLS.PORTFOLIO,
    [MENU_IDS.PENDING_PAYMENTS]: URLS.PENDING_PAYMENTS,
    [MENU_IDS.IDENTITY]: URLS.IDENTITY,
    [MENU_IDS.ZAKAT]: URLS.ZAKAT,
  };

  navigate(locationPerId[itemId]);
};

export const getSelectedMenuItem = (): string => {
  const idPerLocation: Record<string, string> = {
    [URLS.BANK_DETAILS]: MENU_IDS.BANK_DETAILS,
    [URLS.LIVE_DEALS]: MENU_IDS.LIVE_DEALS,
    [URLS.PORTFOLIO]: MENU_IDS.PORTFOLIO,
    [URLS.PENDING_PAYMENTS]: MENU_IDS.PENDING_PAYMENTS,
    [URLS.IDENTITY]: MENU_IDS.IDENTITY,
    [URLS.ZAKAT]: MENU_IDS.ZAKAT,
  };

  const location = window.location.pathname;

  return idPerLocation[location];
};
